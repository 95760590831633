<template>
  <div>
    <v-data-table :headers="headers" :items="skus" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ label }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <button
            type="button"
            class="btn btn-info px-4 py-2 ls1 mb-2 mr-1"
            @click="() => showEditSkuModal(null)"
          >
            New Item
          </button>
          <!-- <button
            type="button"
            class="btn btn-info px-4 py-2 ls1 mb-2"
            @click="showImportModal()"
          >
            Import
          </button> -->
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="() => showEditSkuModal(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="() => removeSkus(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <EditSku
      ref="editSku"
      :actionFunction="editSkus"
      :skuItem="skuItem"
      :carriers="carriers"
      :clientId="clientId"
      :label="label"
    ></EditSku>
    <!-- <ImportData
      ref="importData"
      label="SKU"
      :setData="setImportDataToDataTable"
      :customer="customer"
    >
    </ImportData> -->
  </div>
</template>

<script>
import EditSku from "@/own/components/orderManagement/giftCards/skuContainer/EditSku";
// import ImportData from "@/own/components/fulfillment/asn/skuContainer/ImportData";
export default {
  name: "SkuDatatable",
  props: [
    "setSku",
    "skus",
    "skuItem",
    "carriers",
    "clientId",
    "customer",
    "label",
  ],
  components: {
    EditSku,
    //  ImportData
  },
  data: () => ({}),
  computed: {
    headers() {
      return this.label == "Condition skus"
        ? [
            { text: "SKU ID", value: "sku_id" },
            { text: "System Code", value: "system_code" },
            { text: "Actions", value: "actions", sortable: false },
          ]
        : [
            { text: "SKU ID", value: "sku_id" },
            { text: "System Code", value: "system_code" },
            { text: "Quantity", value: "quantity" },
            { text: "Actions", value: "actions", sortable: false },
          ];
    },
  },
  methods: {
    showImportModal() {
      this.$refs.importData.toggleModal();
    },
    setImportDataToDataTable(importedData) {
      this.setSku([]);
      this.setSku(importedData);
    },
    showEditSkuModal(item) {
      this.$refs.editSku.toggleModal(item);
    },

    editSkus(item, sku_id) {
      // console.log("func", item, sku_id, batch);
      let new_skus = [...this.skus];
      if (sku_id) {
        const index = new_skus.findIndex((item) => item.sku_id == sku_id);
        new_skus.splice(index, 1);
        let copy = new_skus.findIndex((sku) => sku.sku_id == item.sku_id);
        // console.log("edit", copy, new_skus);
        if (copy > -1) {
          new_skus[copy] = item;
        } else {
          new_skus.push(item);
        }
        this.setSku(new_skus);
      } else {
        let copy = new_skus.findIndex((sku) => sku.sku_id == item.sku_id);
        // console.log("add", copy, new_skus);
        if (copy > -1) {
          new_skus[copy] = item;
        } else {
          new_skus.push(item);
        }
        this.setSku(new_skus);
      }
    },
    removeSkus(item) {
      let new_skus = [...this.skus];
      const index = new_skus.findIndex((sku) => sku.sku_id === item.sku_id);

      new_skus.splice(index, 1);
      this.setSku(new_skus);
    },
  },
};
</script>
