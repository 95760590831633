<template>
  <v-dialog
    v-if="isDataLoadedFromServer"
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Edit Record</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-550px scroll-y mx-5 mx-xl-15 py-7"
      >
        <div class="row">
          <div class="col-12 col-sm-6" v-if="!isClient">
            <v-autocomplete
              v-model="formData.customer_id"
              :items="serverData.customers"
              label="Customer"
              item-text="text"
              item-value="index"
              :error-messages="customer_idErrors"
              @input="$v.formData.customer_id.$touch()"
              @blur="$v.formData.customer_id.$touch()"
              clearable
              dense
              outlined
            ></v-autocomplete>
          </div>
          <div class="col-12 col-sm-6">
            <v-text-field
              v-model="formData.name"
              label="Name"
              clearable
              outlined
              dense
              :error-messages="nameErrors"
              @input="$v.formData.name.$touch()"
              @blur="$v.formData.name.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6">
            <v-select
              v-model="formData.payment_type_id"
              label="Payment type"
              item-text="text"
              item-value="index"
              :items="serverData.payment_types"
              clearable
              outlined
              dense
              :error-messages="payment_type_idErrors"
              @input="$v.formData.payment_type_id.$touch()"
              @blur="$v.formData.payment_type_id.$touch()"
            ></v-select>
          </div>
          <div class="col-12 col-sm-6 d-flex align-center">
            <h4 class="pl-2 font-weight-regular">Status</h4>
            <div class="col-4 col-sm-2"></div>
            <v-switch
              :label="statusLabel"
              v-model="formData.status"
              dense
              class="mt-0 pt-0"
            />
          </div>

          <div class="mt-4 pa-0 w-100">
            <sku-datatable
              label="Condition skus"
              :setSku="setConditionSkus"
              :skus="formData.condition_skus"
              :skuItem="serverData.gift_card.apply_skus"
              :clientId="userId"
              :customer="[]"
            ></sku-datatable>
          </div>
          <div class="mt-4 pa-0 w-100">
            <sku-datatable
              label="Apply skus"
              :setSku="setApplySkus"
              :skus="formData.apply_skus"
              :skuItem="serverData.gift_card.apply_skus"
              :clientId="userId"
              :customer="[]"
            ></sku-datatable>
          </div>
        </div>

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center pt-6">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="dialog = false"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitEditForm"
          >
            Update
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import SkuDatatable from "@/own/components/orderManagement/giftCards/skuContainer/SkuDatatable";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "EditItem",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin],
  components: { SkuDatatable },
  validations() {
    return {
      formData: {
        name: { required },
        customer_id: {
          required: requiredIf(function () {
            return !this.isClient;
          }),
        },
        payment_type_id: { required },
      },
    };
  },
  data: () => ({
    dialog: false,
    entries: [],
    search: null,
    isLoading: false,
    serverData: null,
    formData: {
      id: null,
      name: null,
      customer_id: null,
      payment_type_id: null,
      status: false,

      condition_skus: [],
      apply_skus: [],
    },
  }),
  methods: {
    loadDataFromServer() {
      let sendData = { id: this.$store.getters.getItemForAction.id };
      this.pageLoader(true);
      ApiService.post("/order_management/gift_cards/edit", sendData)
        .then((response) => {
          this.serverData = response.data;
          this.resetEditForm();
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    setConditionSkus(item) {
      this.formData.condition_skus = [...item];
    },
    setApplySkus(item) {
      this.formData.apply_skus = [...item];
    },
    submitEditForm() {
      this.pageLoader(true);
      ApiService.post("/order_management/gift_cards/update", this.formData)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `Gift card data has been updated`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.dialog = !this.dialog;
          // this.resetEditForm();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetEditForm() {
      if (this.serverData) {
        let copy = { ...this.serverData.gift_card };
        Object.entries(this.formData).forEach((localD) => {
          Object.entries(this.serverData.gift_card).forEach((serverD) => {
            if (localD[0] === serverD[0]) {
              this.formData[localD[0]] = copy[serverD[0]];
            }
          });
        });
      } else {
        this.formData = {
          id: null,
          name: null,
          customer_id: null,
          payment_type_id: null,
          status: false,

          condition_skus: [],
          apply_skus: [],
        };
      }
    },

    toggleModal() {
      if (this.dialog) {
        // this.resetEditForm();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
  },
  computed: {
    statusLabel() {
      return this.formData.status ? "Enable" : "Disable";
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    customer_idErrors: function () {
      return this.handleFormValidation("customer_id", this);
    },
    nameErrors: function () {
      return this.handleFormValidation("name", this);
    },
    payment_type_idErrors: function () {
      return this.handleFormValidation("payment_type_id", this);
    },
    isClient() {
      return !(
        (this.$store.getters.currentUser.data.user_type == "admin")
        //  ||        this.$store.getters.currentUser.data.user_type == "aggregator"
      );
    },
    userId: function () {
      return this.isClient ? false : this.formData.customer_id;
    },
  },
};
</script>
